@use "../../partials/breakpoints" as *;

.socials {
    display: none;

    @include tablet {
        display: flex; 
        position: fixed;
        right: 1rem;
        top: calc(50% - 3rem);
    }

    &__list {
        list-style: none;
    }

    &__list-item-icon {
        @include tablet {
            width: 1.5rem;
            margin: .5rem;
            filter:invert(0);
        }

        @include desktop {
            margin: .75rem;
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}