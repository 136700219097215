@use "../../partials/breakpoints" as *;
@use "../../partials/fonts" as *;
@use "../../partials/desktop-margin-auto" as *;



.header {
    display: none;

    @include tablet {
        align-items: center;
        margin: 0 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @include desktop-margin-auto;
        padding: 0 2.5rem;
    }

    @include desktop {
        padding: .5rem 2rem;
    }

    &__logo-container {
        display: flex;
        align-items: center;
    }

    &__logo {
        width: 3.5rem;

        @include tablet {
            width: 4rem;
            height: 100%;
        }
    }

    &__logo-link {
        margin: 1rem 0 0.8rem 0 ;
        text-decoration: none;
        color: black;
    }

    &__name {
        margin: 0 0 0 1rem;
        display: flex;
        flex-direction: column;
    }

    &__first-name, 
    &__last-name {
        font-family: "Cooper Hewitt", sans-serif;
        font-weight: 300;
        font-size: 1.5rem;

        @include tablet {
            font-size: 1.85rem;
            line-height: 1.7rem;
            margin-top: .2rem;
        }
    }
    &__nav {
        @include tablet {
            width: 70%;
        }
    }

    &__list {
        list-style: none;
        padding: 0;

        @include tablet {
            padding: 0 1rem;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid lightgrey;
            border-bottom: 1px solid lightgrey;
            display: flex;
        }

        @include desktop {
            padding: 0 3rem;
        }
    }

    &__list-item {
        @include tablet {
            padding: 1rem 1rem .8rem 1rem;
        }
    }

    &__list-item-link {
        text-decoration: none;
        font-size: 1rem;
        font-family: "Kepler", serif;
        color: grey;

        &:hover {
            color: black;
        }

        &--active {
            text-decoration: none;
            font-size: 1rem;
            font-family: "Kepler", serif;
            color: black; 
        }
    }
}


.mobile-header {
    @include tablet {
        display: none;
    }

    &__menu-toggle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1rem;
        z-index: 1;
    }

    &__content {
        width: 100%;
        height: 100%;
        background-color: #F5F6FA;
        overflow: hidden;
        margin: 0;
    }

    &__navigation {
        background-color: white;
        border-bottom: 1px solid black;
        height: 4.375rem;
        display: flex;
    }

    &__logo {
        font-family: "Alternate Gothic", sans-serif;
        width: 3rem;        
        margin: 0 0 0 1.5rem;
        position: absolute;
        top: .875rem;
        left: calc(100vw / 2 - 3.3075rem);
    }

    &__list {
        position: absolute;
        width: 250px;
        height: 100vh;
        top: 1rem;
        left: 3rem;
        box-shadow: 0 0 10px #85888C;
        margin: -3.125rem 0 0 -3.125rem;
        padding: 7.8125rem 2.5rem 2.5rem 2rem;
        background-color: #F5F6FA;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(-100%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    }

    &__list-item {
        padding: 0.625rem 0;
        transition-delay: 2s;
        font-size: .80rem;
        list-style-type: none;
    }

    &__list-item-link {
        text-decoration: none;
        font-size: 1.25rem;
        color:grey;
        &:hover {
            color: black;
        }
    }
}

Hamburger animation
#menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25px;
    left: 25px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
}

#menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
}

#menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: gray;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

#menuToggle input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: gray;
}

#menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
}

#menuToggle input:checked~ul {
    transform: none;
}