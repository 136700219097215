@font-face {
    font-family: "Alternate Gothic";
    src: url("../assets/fonts/AlternateGothicCondATF-Semilight.woff2") format("woff2"),
         url("../assets/fonts/AlternateGothicCondATF-Semilight.woff") format("woff)"),
         url("../assets/fonts/AlternateGothicCondATF-Semilight.ttf") format("ttf");
    font-weight: 350;
}

@font-face {
    font-family: "Alternate Gothic";
    src: url("../assets/fonts/AlternateGothicCondATF-Light.woff2") format("woff2"),
         url("../assets/fonts/AlternateGothicCondATF-Light.woff") format("woff)"),
         url("../assets/fonts/AlternateGothicCondATF-Light.ttf") format("ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Kepler";
    src: url("../assets/fonts/KeplerStd-LightSubh.woff2") format("woff2"),
         url("../assets/fonts/KeplerStd-LightSubh.woff") format("woff)"),
         url("../assets/fonts/KeplerStd-LightSubh.ttf") format("ttf");
}

@font-face {
    font-family: "Lulo Clean";
    src: url("../assets/fonts/LuloCleanW01-OneBold.woff2") format("woff2"),
         url("../assets/fonts/LuloCleanW01-OneBold.woff") format("woff)"),
         url("../assets/fonts/LuloCleanW01-OneBold.ttf") format("ttf");
}

@font-face {
    font-family: "Avant Garde";
    src: url("../assets/fonts/AvantGarde-Book.woff2") format("woff2"),
         url("../assets/fonts/AvantGarde-Book.woff") format("woff)"),
         url("../assets/fonts/AvantGarde-Book.ttf") format("ttf");
}

@font-face {
    font-family: "Cooper Hewitt";
    src: url("../assets/fonts/CooperHewitt-ThinItalic.woff2") format("woff2"),
         url("../assets/fonts/CooperHewitt-ThinItalic.woff") format("woff)"),
         url("../assets/fonts/CooperHewitt-ThinItalic.ttf") format("ttf");
}