@use "../../partials/breakpoints" as *;
@use "../../partials/desktop-margin-auto" as *;

.main {
    &__about {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem;

        @include tablet {
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            gap: 3rem;
            padding: 4rem 6rem;
            @include desktop-margin-auto;
        }

        @include desktop {
            gap: 5rem;
            padding: 4rem 3rem;
        }
    }

    &__image-container {
        width: 85%;

        @include tablet {
            width: 30%;
        }
    }

    &__image {
        width: 100%;
    }

    &__text {
        width: 100%;
        @include tablet {
            width: 30%;
        }
    }

    &__title {
        text-transform: uppercase;
        margin: 0;
        font-size: 2.5rem;
        font-family: "Lulo Clean", sans-serif;
    }

    &__subtitle {
        font-family: "Avant Garde", sans-serif;
        font-size: 1rem;
    }

    &__paragraph {
        font-family: "Avant Garde", sans-serif;
        line-height: 1.5rem;
        font-size: .875rem;
    }

    &__link-container {
        display: flex;
        justify-content: center;
    }

    &__link {
        text-decoration: none;
        color: black;
        padding: .5rem 1.2rem;
        border: 1px solid black;
    }
    
}